.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: #000
    url("https://global-uploads.webflow.com/5d44771a95c1f5121689f944/5f0f5fc77cdeae3e58bbff03_PrimeHelixPit2.jpg")
    no-repeat fixed center;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(7px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-container {
  background-color: rgba(0, 0, 0, 0.7);
  margin: 2vh;
  width: 35%;
}

.leftTeam {
  order: 1;
}

.rightTeam {
  order: 3;
}

.App-logo {
  max-width: 20%;
  max-height: 20%;
  margin: 2vh;
}
.App-logo-container {
  order: 2;
  background-color: rgba(0, 0, 0, 0.7);
  width: 30vh;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.App-button {
  margin: 10px 50px;
  font-size: calc(5px + 1vmin);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border: 2px solid rgba(54, 37, 8, 0.8); /* Green */
}

.App-input {
  width: 100%;
  height: 30px;
  /* font-size: 20px; */
  font-size: calc(5px + 2vmin);
  text-align: center;
}

.App-team-container {
  margin: 20px;
}

.App-player-heroes {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  max-width: 35%;
  margin: 10px;
  flex-wrap: wrap;
}

@media (hover: hover) {
  .App-hero-icon:hover {
    box-shadow: inset 0 0 50px #fff, /* inner white */ inset 20px 0 80px #f0f,
      /* inner left magenta short */ inset -20px 0 80px rgb(255, 208, 0),
      /* inner right cyan short */ inset 20px 0 300px #f0f,
      /* inner left magenta broad */ inset -20px 0 300px #0ff,
      /* inner right cyan broad */ 0 0 50px #fff,
      /* outer white */ -10px 0 80px rgba(156, 90, 4, 0.712),
      /* outer left magenta */ 10px 0 80px rgb(200, 255, 0); /* outer right cyan */
  }

  .App-crazy-glow:hover {
    box-shadow: inset 0 0 50px #fff, /* inner white */ inset 20px 0 80px #f0f,
      /* inner left magenta short */ inset -20px 0 80px rgb(255, 208, 0),
      /* inner right cyan short */ inset 20px 0 300px #f0f,
      /* inner left magenta broad */ inset -20px 0 300px #0ff,
      /* inner right cyan broad */ 0 0 50px #fff,
      /* outer white */ -10px 0 80px rgba(156, 90, 4, 0.712),
      /* outer left magenta */ 10px 0 80px rgb(200, 255, 0); /* outer right cyan */
    cursor: pointer;
  }
  
  .playerNameSelectionBox:hover {
    background-color: orange;
  }
}

.playerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

.App-player-stats {
  font-size: 2vmin;
}

.App-player-name {
  display: flex;
  justify-content: space-between;
}
.App-player-delete {
  width: 20px;
  height: 20px;
  font-size: 13px;
}
.App-hero-icon {
  width: 100%;
  height: auto;
  max-height: 50px;
  max-width: 50px;
}

.App-hero-container {
  /* margin-bottom: -6px; */
  height: 50px;
}

.App-crazy-glow {
  /* box-shadow: 3px 2px 20px 0.1em; */
  animation: App-crazy-glow infinite 3s linear;
}


@keyframes App-crazy-glow {
  0% {
    box-shadow: 3px 2px 20px 0.1em
  }
  50% {
    box-shadow: 3px 2px 20px -0.5em;
  }
  100% {
    box-shadow: 3px 2px 20px 0.1em;
  }
}


@media only screen and (max-width: 1500px) {
  .App-container {
    min-width: 100%;
    margin: 0;
  }
  .rightTeam {
    order: 2;
  }
  .App-logo {
    max-width: 40vh;
    max-height: 60%;
  }
  .App-logo-container {
    width: 90vh;
    height: 10vh;
    order: 3;
    /* max-width: 80%;
    min-height: 65%;
    order: 3; */
  }
  .App-player-stats {
    font-size: 3vmin;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.playerNameSelectionBox {
  background-color: rgba(100, 100, 100, 0.2);
}
